import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Role } from '../../../shared/enums';
import { RolesService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class RolesResolver {
  constructor(private readonly rolesService: RolesService) {}

  resolve(): Observable<Role[]> | Promise<Role[]> | Role[] {
    return this.rolesService.getUserRoles();
  }
}
